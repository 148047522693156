import IMG_1 from "../../../assets/images/$NOT img.png";
import IMG_2 from "../../../assets/images/Donnie Katana img.png";
import IMG_3 from "../../../assets/images/Eem Triplin img.png";

export const data = [
  {
    name: "$NOT",
    img: IMG_1,
    link: "https://open.spotify.com/artist/5IbEL2xjRtKsunfmsahLuO?si=aA3bkeRET5m8VloH25JFLw&nd=1",
  },
  {
    name: "Eem Triplin",
    img: IMG_3,
    link: "https://open.spotify.com/artist/5kxnZh8gXyXdIvCWbDMevT?si=ixC_5nv3RkKp3BeUTAEQKg",
  },
  {
    name: "Donnie Katana",
    img: IMG_2,
    link: "https://www.instagram.com/donniekatana/?hl=en",
  },
];
