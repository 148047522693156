import BackgroundVideo from "../../components/BackgroundVideo/BackgroundVideo";
import { Box } from "../../components/StyledComponent";
import styled from "styled-components";
import { SocialIcons } from "../../components";
import LOGO_IMG from "../../assets/images/logo.gif";
import { Router } from "react-router-dom";
import { Link } from "react-router-dom";
import { RoutePattern } from "../../routes/RoutePattern";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

export default function Landing() {
  const { currentRoute } = useSelector((state: RootState) => state.Routes);

  return (
    <Box className="text-white w-full flex flex-col justify-center items-center bg-black">
      <div className="h-screen overflow-hidden relative w-full">
        {/* <BackgroundVideo /> */}
        {currentRoute !== "/home" ? (
          <Link to={RoutePattern.HOME}>
            <img
              src={LOGO_IMG}
              alt=""
              className="w-full md:w-3/4 h-full mx-auto object-cover"
            />
          </Link>
        ) : (
          <img
            src={LOGO_IMG}
            alt=""
            className="w-full md:w-3/4 h-full mx-auto object-cover"
          />
        )}
      </div>
    </Box>
  );
}
