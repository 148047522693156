import { RoutePattern } from "../../../routes/RoutePattern";
import { data } from "./data";
import styled from "styled-components";

const Artist = styled.a``;

const Artists = () => {
  return (
    <div
      id={RoutePattern.ROSTER}
      className="text-white w-full flex flex-col justify-center items-center bg-black py-16 min-h-screen z-50"
    >
      <div className="flex flex-col md:flex-row">
        {data.map((item, idx) => (
          <Artist
            key={idx}
            href={item.link}
            target="_blank"
            rel="noreferrer"
            className="text-center mt-10 md:mt-0"
          >
            <img
              src={item.img}
              alt={item.name}
              className="w-full h-full object-cover md:px-10"
            />
            <span className=" text-white capitalize mt-5 block">
              {item.name}
            </span>
          </Artist>
        ))}
      </div>
    </div>
  );
};

export default Artists;
