import { Box } from "../../components/StyledComponent";
import styled from "styled-components";
import { SocialIcons } from "../../components";
import { Carousel } from "react-responsive-carousel";
import VIDEO1 from "../../assets/videos/doja.mp4";
import VIDEO2 from "../../assets/videos/tell-em.mp4";
import VIDEO3 from "../../assets/videos/louie-v.mp4";
import VIDEO4 from "../../assets/videos/awkward.mp4";
import { ArrowTypes } from "../../components/YoutubeSlider/types";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shuffle } from "../../helpers";

export const Arrow = styled.button<ArrowTypes>`
  position: absolute;
  z-index: 2;
  top: 50%;
  right: ${(props) => props.right && "3%"};
  left: ${(props) => props.left && "3%"};
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }
`;

const data = [
  {
    video: VIDEO1,
  },

  {
    video: VIDEO2,
  },
  {
    video: VIDEO3,
  },
  {
    video: VIDEO4,
  },
];

const ScrollDown = styled.button`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 123123;

  @media (min-width: 768px) {
    bottom: 2%;
  }
`;

export default function Home() {
  const renderArrowPrev = (
    onClickHandler: any,
    hasPrev: boolean,
    label: string
  ) => (
    <Arrow
      type="button"
      onClick={onClickHandler}
      left="15%"
      title={label}
      className="from-lef"
    >
      <FontAwesomeIcon icon={faAngleLeft as any} />
    </Arrow>
  );

  const renderArrowNext = (
    onClickHandler: any,
    hasNext: boolean,
    label: string
  ) => (
    <Arrow
      type="button"
      onClick={onClickHandler}
      right="15%"
      title={label}
      className="from-righ"
    >
      <FontAwesomeIcon icon={faAngleRight as any} />
    </Arrow>
  );

  return (
    <Box className="text-white w-full flex flex-col justify-center items-center py-0 overflow-hidden relative">
      {/* <Recordings /> */}
      {/* <Video /> */}
      <Carousel
        infiniteLoop
        swipeable={false}
        emulateTouch={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
      >
        {shuffle(data as any).map((item: any, idx) => (
          <video
            key={idx}
            className="w-full h-full"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={item.video} type="video/mp4" />
          </video>
        ))}
      </Carousel>
      <div className="w-5/6 pt-16 md:3/4 mx-auto">
        <h2 className="text-white text-center text-2xl md:text-4xl font-bold mb-5 text-shadow">
          FOUNDED IN 2020, MARKOW, INC. IS AN INDEPENDENT MUSIC COMPANY BASED IN
          LOS ANGELES, CA. WHETHER IT'S ON THE ROAD OR IN THE STUDIO, OUR
          PASSION AT MARKOW, INC. IS ARTIST MANAGEMENT AND DEVELOPMENT WITH
          FOCUS, STRENGTH, AND BOLDNESS.
        </h2>
      </div>
    </Box>
  );
}
