const Shop = () => {
  return (
    <div className="text-white font-bold uppercase flex flex-col justify-center items-center min-h-screen">
      <div>
        <h2 className="text-3xl">Coming soon</h2>
      </div>
      <form className="flex flex-col mt-5">
        <input
          type="text"
          placeholder="Email"
          className="py-2 px-4 rounded-md text-black"
        />
        <button className="mt-2 block" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Shop;
