import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMusic } from "@fortawesome/free-solid-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isEmail?: boolean;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faInstagram,
    link: "https://www.instagram.com/markow.inc/",
  },
  {
    icon: faEnvelope,
    link: "info@markow.inc",
    isEmail: true,
  },
];
