import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: RoutePattern.HOME,
    to: "/home",
    isRoute: false,
    isAnchor: false,
  },
  {
    name: "shop",
    to: "/shop",
    isRoute: false,
    isAnchor: false,
  },
  {
    name: RoutePattern.ROSTER,
    to: "/" + RoutePattern.ROSTER,
    isRoute: false,
    isAnchor: true,
  },
  // {
  //   name: RoutePattern.VIDEOS,
  //   to: "/home#videos",
  //   isRoute: true,
  // },
];
